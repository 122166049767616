<template>
  <b-card class="blog-edit-wrapper">
    <!-- form -->
    <div class="text-center">
      <h4 class="mt-5">
        Code Generator
        <feather-icon icon="Edit2Icon" />
      </h4>
    </div>
    <validation-observer ref="addCourseFormvalidate">
      <b-form class="mt-2" style="width: 100%" @submit.prevent="save">
        <b-row>
          <b-col md="6">
            <b-form-group
              label="Category"
              label-for="blog-edit-category"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="Category"
                rules=""
              >
                <v-select
                  id="blog-edit-category"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  v-model="addCourseForm.category_id"
                  label="type"
                  :options="categoriesList"
                  :reduce="(val) => val.id"
                  @input="getCoursesByCategory(addCourseForm.category_id)"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Course"
              label-for="blog-edit-category"
              class="mb-2"
            >
              <validation-provider #default="{ errors }" name="Course" rules="">
                <v-select
                  id="blog-edit-category"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  v-model="addCourseForm.course_id"
                  label="name"
                  :options="coursesList"
                  :reduce="(val) => val.id"
                  >
                <template #no-options="{ search, searching, loading }">
                  No data ...
    </template>
  </v-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group
              label="Discount"
              label-for="blog-content"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="Description"
                rules="required"
              >
                <b-form-input type="number" v-model="addCourseForm.discount" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group
              label="Users Number"
              label-for="blog-edit-course"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="Courses"
                rules=""
              >
                <b-form-input type="number" v-model="addCourseForm.users" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group label="email" label-for="blog-content" class="mb-2">
              <validation-provider
                #default="{ errors }"
                name="email"
                rules="required"
              >
                <b-form-input v-model="addCourseForm.email" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group
              label="Company Name"
              label-for="blog-edit-course"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="Courses"
                rules=""
              >
                <b-form-input v-model="addCourseForm.companyName" />

                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group
              label="Start Date"
              label-for="blog-edit-course"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="Courses"
                rules=""
              >
                <b-form-datepicker
                  id="example-datepicker2"
                  v-model="addCourseForm.expire_date"
                  class="mb-1"
                />
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col cols="12" class="mt-50">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-1"
              @click="save"
            >
              GENERATE CODE
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
   <b-row>
   <GenertCodeList ref="child" style="width:100%"></GenertCodeList>
   
   </b-row>
    <!--/ form -->
  </b-card>
</template>

<script>
import {
  BCard,
  BMedia,
  BFormRadio,
  BFormCheckbox,
  BFormRadioGroup,
  BCardText,
  BMediaAside,
  BMediaBody,
  BForm,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BImg,
  BFormFile,
  BLink,
  BButton,
  BTable,
  BFormDatepicker,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import vSelect from "vue-select";
import { quillEditor } from "vue-quill-editor";
import { required, email } from "@validations";
import Ripple from "vue-ripple-directive";
import { useInputImageRenderer } from "@core/comp-functions/forms/form-utils";
import { ref, reactive } from "@vue/composition-api";
import store from "@/store";
import Vue from "vue";
import GenertCodeList from "./GenertCodeList.vue";

export default {
  setup() {
    // const store = useStore();
    const refInputEl = ref(null);
    const refInputE2 = ref(null);
    const refPreviewEl = ref(null);
    const refPreviewE2 = ref(null);
    const Tabimage = ref("");
    const coursesList = ref([]);
    const categoriesList = ref([]);
    const child=ref()

    const getCoursesByCategory = (id) => {
      addCourseForm.course_id = "";
      store
        .dispatch("categories/getCoursesByCategory", {
       
       
      ' category_id':id

   
   })
        .then((response) => {
       
          coursesList.value = response.data.data;
        });
    };

    store.dispatch("categories/AllCategory").then((response) => {
      
      categoriesList.value = response.data.data;
    });
    const addCourseForm = reactive({
      email: "",
      expire_date: "",
      users: "",
      companyName: '',
      category_id: '',
      course_id: "",
      discount: "",
     
    });
    const { inputImageRenderer } = useInputImageRenderer(
      refInputEl,
      (base64) => {
        refPreviewEl.value.src = base64;
      }
    );
    const { inputImageRendererTab } = useInputImageRenderer(
      refInputE2,
      (base64) => {
        refPreviewE2.value.src = base64;
      }
    );
    const addCourseFormvalidate = ref();

    const save = () => {
      ;
      const formData = new FormData();

      addCourseFormvalidate.value.validate().then((success) => {
        if (success) {
          formData.append("email", addCourseForm.email);
          formData.append("expire_date", addCourseForm.expire_date);
          formData.append("users", addCourseForm.users);
          formData.append("companyName", addCourseForm.companyName);
          formData.append("category_id", addCourseForm.category_id);
          formData.append("course_id", addCourseForm.course_id);
          formData.append(" discount", addCourseForm.discount);

          store
            .dispatch("setting/addGenerateCourse", formData)
            .then((response) => {
              child.value.getAll()
              Vue.swal({
                title: " Added ",
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
            })
            .catch((error) => {
              Vue.swal({
                title: "",
                text: `${error.response.data.message}`,
                icon: "error",
                confirmButtonText: "Ok",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
            });

         
        }
      });
    };
    const tableColumns = [
      { key: "id", label: "ID", sortable: true },
      { key: "name", label: "Gategory", sortable: true },
      { label: "Course", key: "link_id", sortable: true },
      
      { label: "Email", key: "email", sortable: true },
      { key: "users", label: "Users", sortable: true },
      { label: "Expire Date", key: "date", sortable: true },
      { key: "code", label: "Code", sortable: true },
      { label: "Company Name", key: "com", sortable: true },
      { key: "dis", label: "Discount", sortable: true },

      { key: "actions" },
    ];
    return {
      refInputEl,
      refPreviewEl,
      tableColumns,
      refInputE2,
      refPreviewE2,
      inputImageRendererTab,
      Tabimage,
      addCourseForm,
      inputImageRenderer,
      getCoursesByCategory,
      addCourseFormvalidate,
      coursesList,
      categoriesList,
      coursesList,
      child,

      save,
      required,
      email,
    };
  },

  components: {
    BCard,
    BMedia,
    BTable,
    ValidationProvider,
    ValidationObserver,
    BCardText,
    BMediaAside,
    BMediaBody,
    BFormRadio,
    BFormDatepicker,
    BFormCheckbox,
    BFormRadioGroup,
    BForm,
    BLink,
    BImg,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormFile,
    vSelect,
    quillEditor,
    GenertCodeList
},

  directives: {
    Ripple,
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/quill.scss";
@import "@core/scss/vue/pages/page-blog.scss";
</style>
<style>
  .mb-2.col-12 {
    margin: -1px !important;
  }
   .border.rounded.p-2 {
    margin: 8px 1px !important;
  }
  .col-12 {
    margin: 0px;
  }
  form.mt-2 {
    margin-bottom: 12px;
}
td {
   font-size: 12px  !important;
}
  
  </style>